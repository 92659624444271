import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../Firebase';
import { useState } from 'react';
import { useEffect } from 'react';
import LandingPage from '../SmallComponents/LandingPage';
import { Sharebutton } from '../SmallComponents/SmallUIComponents';
import {Loader} from '../SmallComponents/Loader';
import dhore from '../assets/Dhore.svg'
import camel from '../assets/camel.svg'

// import * as linkify from 'linkifyjs';
// import linkifyHtml from 'linkify-html';
import Drawer from './Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveBlog } from '../Redux/Slices/activeBlogSlice';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay, Pagination } from 'swiper';

import Cookies from 'js-cookie';


export default function QuestionBody(props) {
    const { qid } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const clicked_question = useSelector(state => state.ActiveBlog.ActiveBlog)
    SwiperCore.use([Autoplay, Pagination])

    // const [clicked_question, setclicked_question] = useState([])
    // const [Time, setTime] = useState()
    // const [Date, setDate] = useState()
    const [Loading, setLoading] = useState(false)
    // const linkRegex = /((http|https):\/\/[^\s]+)/g;

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const docRef = doc(db, "root", qid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("fetching data..");
                // setclicked_question(docSnap.data())
                dispatch(setActiveBlog(docSnap.data()))
                setLoading(false)
                document.getElementById("content").innerHTML = docSnap.data().content
            } else {
                alert('Blog Url is Invalid')
            }
        }
        // getting blog data by blog id
        // getData()
        if (clicked_question.length === 0) {
            getData()
        }
        else {
            document.getElementById("content").innerHTML = clicked_question.content
        }

    }, [])


    // whenever user click on share button
    function handleShareClick() {
        if (navigator.share) {
            navigator.share({
                title: "Praveen Kumar Makwana Blogs",
                text: "Check out this blog by Praveen Kumar Makwana",
                url: `${window.location.href}`,
            });
        } else {
            alert("Sharing is not supported on this device/browser.");
        }
    }


    // enlarging image when user clicks on a image
    const [data, setData] = useState('')
    const ViewImage = (img) => {
        setData(img)
        // console.log("here is the sending data : "+img);
    }
    useEffect(() => {
        if (data !== '') {
            navigate('/img', {
                state: {
                    image: data
                }
            })
        }
    }, [data])

    // Important peice of code for view count
    useEffect(() => {
        if (Cookies.get(`viewed_${qid}`) === undefined) {
            Cookies.set(`viewed_${qid}`, 'true', { expires: 365, path: `/blog/${qid}` });
            Inc()
        }
        else if (Cookies.get(`viewed_${qid}`) === 'true') {
            console.log("viewed");
        }
        else {
            console.log("Else condition");
        }
    }, [])

    // for increment of views when user loads page for first time
    const Inc = async () => {
        const docRef = doc(db, `views`, qid);
        await updateDoc(docRef, {
            'viewCount': increment(1)
        })
    }

    // scroll to section
    function scrollToSection(key) {
        if (key !== null && key !== undefined) {
            window.scrollTo({
                top: document.getElementById(key).offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        }
    };



    // function for fetching blog views
    const [BlogView, setBlogView] = useState('Loading ')
    const FetchViews = async () => {
        const view = await getDoc(doc(db, "views", qid))
        setBlogView(view.data().viewCount)
    }
    useEffect(() => {
        FetchViews()
    }, [])

    return (
        <>
            <LandingPage isBlog={true} />
            <div className="base">
                {/* <p>{qid}</p> */}
                <div className="question-body" id='qb'>
                    <div className="up" onClick={() => scrollToSection('qb')}><i class="fas fa-long-arrow-alt-up"></i></div>
                    <img src={dhore} alt="" className='dhore' />
                    <img src={camel} alt="" className='camel' />
                    {
                        Loading ? <Loader />
                            :
                            <div>
                                {/* all the details presented in the question body */}
                                <div className="question-title">
                                    {clicked_question.title}
                                </div>
                                <div className="Gap"></div>
                                <div className="timeDate">{clicked_question.createdAt?.toDate().toDateString()} | {clicked_question.createdAt?.toDate().toLocaleTimeString()}</div>
                                <div className="Gap"></div>
                                <div className="Gap"></div>
                                <div className="flex">
                                    <div className="Category">{clicked_question.category}</div>
                                    <div className="ViewCount">
                                        <i class="far fa-eye"></i>
                                        {/* {clicked_question.viewCount} views */}
                                        {BlogView==="Loading "?"Loading":`${Math.floor(BlogView + ((BlogView*35)/100))} views`}
                                    </div>
                                </div>
                                <div className="Gap"></div>
                                <div className="Gap"></div>
                                <div className="Gap"></div>
                                {
                                    clicked_question.attachedImage?.length !== 0 ?
                                        <div>
                                            <Swiper
                                                modules={[Autoplay]}
                                                className="ImagesSwiper"
                                                spaceBetween={6}
                                                slidesPerView={1}
                                                autoplay={{ delay: 3000, disableOnInteraction: false }}
                                                pagination={true}
                                            // loop={true}
                                            >
                                                {clicked_question.attachedImage?.map((key) => (

                                                    <SwiperSlide className='ImageCard'
                                                        onClick={() => ViewImage(key.url)}
                                                        style={{ background: `url("${key.url}") no-repeat center/cover` }}>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                            <div className="Gap"></div>
                                            <div className="Gap"></div>
                                            <div className="Gap"></div>
                                        </div>
                                        : ''
                                }


                                {/* question content */}
                                <div id='content' style={{ marginTop: '28px', fontSize: '16px', lineHeight: '30px' }}></div>
                                <Sharebutton Action={handleShareClick} />
                                <u><p onClick={() => { navigate('/') }}>Read more blogs...</p></u>
                            </div>
                    }
                </div>
                <Drawer />
            </div>
        </>
    )
}