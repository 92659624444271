import React from 'react'
import '../Styles/Loader.css'

export function Loader() {
  return (
    <div className="load-row">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export function LoaderTwo() {
  return (
    <svg className='al' viewBox="25 25 50 50">
      <circle r="20" cy="50" cx="50"></circle>
    </svg>
  )
}

