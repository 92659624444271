import React, { useEffect } from 'react'
import '../Styles/About.css'
import Drawer from '../MajorComponents/Drawer'
import LandingPage from './LandingPage'
import { db } from '../Firebase'
import { doc, getDoc } from 'firebase/firestore'
import { useState } from 'react'

export default function About() {

    const [Loading, setLoading] = useState(false)
    const [UserDetails, setUserDetails] = useState(null)

    const getDetails = async () => {
        setLoading(true)
        const docRef = doc(db, "user", "userDetails");
        await getDoc(docRef).then((res) => {
            setUserDetails(res.data());
            document.getElementById('aboutuser').innerHTML = res.data().About
            setLoading(false)
        }).catch((err) => {
            console.log(err.message);
            setLoading(false)
        })
    }

    useEffect(() => {
        getDetails()
    }, [])

    return (
        <div className='about-container-wrapper'>
            <LandingPage />
            <div className="about-container">
                <h5>{UserDetails?UserDetails.Name:"Loading..."}</h5>
                <p>Phone - +91 {UserDetails?UserDetails.Phone:"Loading..."}</p>
                <p id='aboutuser'>
                    
                </p>

                {/* <h5>Read blogs on other platforms</h5>   
                <div className="line"></div>
                <a href= "https://pawansarswatmuklawa.blogspot.com/" target='_blank'>Blogspot.com</a>
                <div className="line"></div>
                <a href= "https://agripawan.wordpress.com/blog/" target='_blank'>Wordpress.com</a>
                <div className="line"></div> */}
            </div>
            <Drawer />
        </div>
    )
}

