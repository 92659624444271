import React, { useRef } from 'react'
import '../Styles/Gallery.css'
import Masonry from "react-responsive-masonry"
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { db, storage } from '../Firebase';
import { deleteObject, ref, ref as sRef } from "firebase/storage";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import { collection, deleteDoc, doc, getDocs, limit, orderBy, query, setDoc, startAfter, Timestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationPopup, UniversalLoader } from '../SmallComponents/SmallUIComponents'
import '../Styles/SmallUIComponents.css'
import { GetImages, deleteImages, setImages, setLastImage } from '../Redux/Slices/GallerySlice'
import { Loader } from '../SmallComponents/Loader'
import Drawer from './Drawer'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay, Pagination } from 'swiper';

export default function Gallery() {

    const [Loading, setLoading] = useState(false)
    const [isReachedBottom, setisReachedBottom] = useState(false)
    const [ImgUploadPopup, setImgUploadPopup] = useState(false)
    const [LoadingMoreImages, setLoadingMoreImages] = useState(false)
    // const [Img, setImg] = useState(null)
    // const [URL, setURL] = useState('')

    const aboutRef = useRef('')
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // const ImgUrls = useSelector(state => state.Gallery.ImgUrls)
    const Images = useSelector(state => state.Gallery.FetchedImages)
    const LastImage = useSelector(state => state.Gallery.LastImage)
    const FetchingImages = useSelector(state => state.Gallery.Loading)

    SwiperCore.use([Autoplay, Pagination])


    // for getting images when user enters first time in the gallery
    useEffect(() => {
        if (Images.length === 0) {
            dispatch(GetImages())
        }
    }, [dispatch])


    // checking user is logged in or not
    const accessToken = useSelector(state => state.Auth.Auth)
    const UserLoad = useSelector(state => state.Auth.Loading)
    const [IsAuth, setIsAuth] = useState(false)
    useEffect(() => {
        if (accessToken !== null) {
            setIsAuth(true)
        }
    }, [UserLoad])


    // enlarging image when user clicks on a image
    const [data, setData] = useState('')
    const ViewImage = (img) => {
        setData(img)
        // console.log("here is the sending data : "+img);
    }
    useEffect(() => {
        if (data !== '') {
            navigate('/img', {
                state: {
                    image: data
                }
            })
        }
    }, [data])


    const ImagesUploadLimit = 3
    var imagesUpload = []; // for carring the images to be uploaded.
    var ForImagePreview = []; //For carring the pre urls of the images to be uploaded.
    const imageUrls = []; // For carring the urls of the images after upload
    const [pI, setPI] = useState() //Just for holding values
    const [uIm, setUIm] = useState() // just for holding values

    const handleSubmit = (e) => {
        // Only allowed file
        const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];

        if (e.target.files.length <= ImagesUploadLimit) {
            for (let i = 0; i < e.target.files.length; i++) {
                const newImage = e.target.files[i];

                if (ALLOWED_TYPES.includes(newImage.type) && e.target.files[i].size < 8388608) {
                    imagesUpload.push(newImage);
                    // generating fake/preUrls for images preview on dom before upload
                    ForImagePreview.push({ preURL: global.URL.createObjectURL(newImage) });
                }

                else {
                    alert(`File ${newImage.name} is not supported or bigger than 8 MB`);
                    return
                }
            }
            console.log("Preview Links", ForImagePreview);
            console.log("Files For uploading", imagesUpload);
            setPI(ForImagePreview)
            setUIm(imagesUpload)
            setImgUploadPopup(true)


            // firing upload function
            // uploadImage(imagesUpload);
        }
        else {
            alert(`Sorry :-( You can select maximum ${ImagesUploadLimit} images.`)
        };
    };

    // uploading image
    const uploadImage = async () => {
        if (uIm.length === 0) {
            console.log("Nothing for upload");
            return
        };
        setLoading(true)
        for (let i = 0; i < uIm.length; i++) {
            const imageRef = sRef(storage, `gallery/${uuidv4()}`);
            await uploadBytes(imageRef, uIm[i]).then(() => {
                console.log(`Image ${i} uploaded successfully`);
                getDownloadURL(imageRef).then((url) => {
                    console.log("Here is the link", url);
                    imageUrls.push({
                        url: url,
                    });
                    console.log("Img urls length : " + imageUrls.length + ' number of imgs. : ' + uIm.length);
                    if (imageUrls.length === uIm.length) {
                        console.log("Now i can upload object url");
                        UploadURL(imageUrls)
                    }
                });
            });
        }
    }

    const UploadURL = async (urls) => {
        const myTimestamp = Timestamp.fromDate(new Date());
        await setDoc(doc(db, "gallery", uuidv4()), {
            ImgAbout: aboutRef.current.value,
            ImgURL: urls,
            createdAt: myTimestamp
        }).then(() => {
            console.log("url updated success");
            navigate(0)
            setLoading(false)
            setImgUploadPopup(false);
        }).catch((err) => {
            console.log(err.message);
            setLoading(false)
            setImgUploadPopup(false);
        })
    }

    // fetching next images on reached bottom to the page
    const FetchNextImages = async () => {
        setLoadingMoreImages(true)
        let newImages = [];
        const newImagesQuery = query(collection(db, 'gallery'), orderBy("createdAt", "desc"), limit(2), startAfter(LastImage))
        if (LastImage === undefined) { setLoadingMoreImages(false) }
        const querySnapshot = await getDocs(newImagesQuery).catch((err) => { setLoadingMoreImages(false) })
        querySnapshot.forEach((doc) => {
            newImages.push({
                ImageAbout: doc.data().ImgAbout,
                ImageUrl: doc.data().ImgURL,
                createdAt: doc.data().createdAt,
                ImgID: doc.id
            })
        })
        dispatch(setImages(newImages))
        const last = querySnapshot.docs[querySnapshot.docs.length - 1];
        dispatch(setLastImage(last));
        setisReachedBottom(false)
        setLoadingMoreImages(false)
    }

    useEffect(() => {
        function handleScroll() {
            if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
                setisReachedBottom(true)
                console.log("bottom reached");
            }
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    useEffect(() => { if (isReachedBottom) { FetchNextImages() } }, [isReachedBottom])


    return (
        <div className='gallery-container-wrapper'>
            <div className="gallery-container">
                {IsAuth ? <label className='AddImageButton'>
                    <i className="fas fa-upload fa-sm"></i>
                    &nbsp;
                    &nbsp;
                    Add a image
                    <input type="file" id="fileUpload" className="forHide" multiple onChange={handleSubmit} />
                </label> : <h3 style={{
                    margin: "26px 0px"
                }}>Gallery</h3>}
                <Masonry columnsCount={1} gutter="8px">
                    {Images.map((key) => (
                        <ImageContainer
                            IsAuth={IsAuth}
                            ViewImage={ViewImage}
                            About={key.ImageAbout}
                            Time={key.createdAt.toDate().toLocaleTimeString()}
                            Date={key.createdAt.toDate().toDateString()}
                            ImagesURLlist={key.ImageUrl}
                            ImgID={key.ImgID}
                        />

                    ))}
                </Masonry>
                {
                    FetchingImages === 'loading' ? <UniversalLoader /> : ''
                }
                {
                    LoadingMoreImages ? <Loader /> : ''
                }
                {
                    ImgUploadPopup ? <div className='ImgUploadPopupWrapper'>
                        <div className='ImgUploadPopup'>
                            <div id="imgPreview">
                                {pI.map((key) => (<img src={key.preURL} alt="" />))}
                            </div>
                            <input type="text" className='Imginput' placeholder='Add Caption (Optional)' ref={aboutRef} />
                            <div className="cnf_actions">
                                <button className="action" onClick={() => { setImgUploadPopup(false) }}>Cancel</button>
                                <button className="action PrimaryAction" onClick={uploadImage}>Upload</button>
                            </div>
                        </div>
                    </div> : ''
                }
                {
                    Loading ? <UniversalLoader /> : ''
                }
            </div>
            <Drawer />
        </div>
    )
}

function ImageContainer(props) {

    const [DeletingImage, setDeletingImage] = useState(false)
    const [Cnf_Popup, setCnf_Popup] = useState(false)
    // const navigate = useNavigate()
    const dispatch = useDispatch()

    // deleting images by scraping image name from its url
    const GetImgName = () => {
        for (let l = 0; l < props.ImagesURLlist.length; l++) {
            let url = props.ImagesURLlist[l].url
            console.log(url);
            if (url !== "") {
                let Clipback = url.substring(url.indexOf("gallery%2F"), url.indexOf("?"))
                let ClipFront = Clipback.replace("gallery%2F", "")
                let desertRef = ref(storage, `gallery/${ClipFront}`);
                deleteObject(desertRef).then(() => {
                    console.log("file deleted successfully");
                    setDeletingImage(false)
                    dispatch(deleteImages(props.ImgID))
                    // navigate(0)
                }).catch((error) => {
                    console.log("error: " + error.message);
                    setDeletingImage(false)
                });
            }
        }
    }

    // deleting image documents
    const deleteImage = () => {
        setDeletingImage(true)
        deleteDoc(doc(db, "gallery", props.ImgID))
            .then(() => {
                GetImgName()
                setCnf_Popup(false)
            })
    }

    const closePopup = () => { setCnf_Popup(false) }

    return <div className='ImagesContainerBox'>
        <p className='question-title'>{props.About}</p>
        {props.IsAuth ? <div className='delete_photos' onClick={() => { setCnf_Popup(true) }}>
            <i className="fas fa-trash-alt fa-sm"></i>
        </div> : ''}
        <div className="timeDate">{props.Date} | {props.Time} </div>
        <div className="Gap"></div>
        <div className="Gap"></div>
        <div className="Gap"></div>
        <div className="Gap"></div>
        <div>
            <Swiper
                modules={[Autoplay]}
                className="ImagesSwiper"
                spaceBetween={6}
                slidesPerView={2}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                pagination={true}
            // loop={true}
            >
                {props.ImagesURLlist.map((flag) => (

                    <SwiperSlide className='ImageCard'
                        onClick={() => props.ViewImage(flag.url)}
                        style={{ background: `url("${flag.url}") no-repeat center/cover` }}>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="Gap"></div>
            <div className="Gap"></div>
            <div className="Gap"></div>
            <div className="Gap"></div>
            <div className="Gap"></div>
            <div className="line"></div>
        </div>





        {/* delete confirmation popup */}
        {
            Cnf_Popup ? <ConfirmationPopup
                statement={"Are you sure you want to delete these Images?"}
                pTitle={"Delete"}
                sTitle={"Cancel"}
                pAction={deleteImage}
                sAction={closePopup}
            /> : ''
        }
    </div>
}


