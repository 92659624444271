import React, { useRef, useState } from 'react'
import { db, storage } from '../Firebase';
import { ref as sRef } from "firebase/storage";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import '../Styles/Addblog.css'
import '../Styles/Gallery.css'
// import "react-draft-wysiwyg/ dist/react-draft-wysiwyg.css";
import upload from '../upload.svg'
import send from '../send.svg'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { UniversalLoader, catgs } from '../SmallComponents/SmallUIComponents';
import { useNavigate } from 'react-router';


export default function AddBlog() {
    const titleRef = useRef(null)
    const navigate = useNavigate()
    // const [URL, setURL] = useState('')
    const [value, setValue] = useState('');
    const [Loading, setLoading] = useState(false)

    // const handleSubmit = (e) => {
    //     const image = e.target.files[0];
    //     console.log("Image Selected", image)
    //     const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
    //     if (image && ALLOWED_TYPES.includes(image.type)) {
    //         document.getElementById("Loader").style.display = "block";
    //         uploadImage(image)
    //     }
    //     else {
    //         alert("File format not supported")
    //     }
    // }



    const ImagesUploadLimit = 3
    var imagesUpload = []; // for carring the images to be uploaded.
    var ForImagePreview = []; //For carring the pre urls of the images to be uploaded.
    const imageUrls = []; // For carring the urls of the images after upload
    const [pI, setPI] = useState([]) //Just for holding values
    const [uIm, setUIm] = useState([]) // just for holding values

    const handleSubmit = (e) => {
        // Only allowed file
        const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];

        if (e.target.files.length <= ImagesUploadLimit) {
            for (let i = 0; i < e.target.files.length; i++) {
                const newImage = e.target.files[i];

                if (ALLOWED_TYPES.includes(newImage.type) && e.target.files[i].size < 8388608) {
                    imagesUpload.push(newImage);
                    // generating fake/preUrls for images preview on dom before upload
                    ForImagePreview.push({ preURL: global.URL.createObjectURL(newImage) });
                }

                else {
                    alert(`File ${newImage.name} is not supported or bigger than 8 MB`);
                    return
                }
            }
            console.log("Preview Links", ForImagePreview);
            console.log("Files For uploading", imagesUpload);
            setPI(ForImagePreview)
            setUIm(imagesUpload)
        }
        else {
            alert(`Sorry :-( You can select maximum ${ImagesUploadLimit} images.`)
        };
    };





    // const uploadImage = async (image) => {
    //     // uploading image
    //     const fileRef = sRef(storage, `${uuidv4()}`);
    //     await uploadBytes(fileRef, image)
    //     // getting url of the image to store in user object
    //     getDownloadURL(fileRef).then((url) => {
    //         setURL(url);
    //         document.getElementById("Loader").style.display = "none";
    //         console.log(URL);
    //     }).catch((error) => {
    //         console.log("Error in getting image url", error);
    //     });
    // }


    const uploadImage = async () => {
        if (uIm.length === 0) {
            console.log("Nothing for upload");
            const content = LinksManagement(value)
            publishBlog(content)
        }
        else {
            setLoading(true)
            for (let i = 0; i < uIm.length; i++) {
                const imageRef = sRef(storage, uuidv4());
                await uploadBytes(imageRef, uIm[i]).then(() => {
                    console.log(`Image ${i} uploaded successfully`);
                    getDownloadURL(imageRef).then((url) => {
                        console.log("Here is the link", url);
                        imageUrls.push({
                            url: url,
                        });
                        console.log("Img urls length : " + imageUrls.length + ' number of imgs. : ' + uIm.length);
                        if (imageUrls.length === uIm.length) {
                            console.log("Now i can upload object url");
                            // UploadURL(imageUrls)
                            // ValidateFeilds()
                            const content = LinksManagement(value)
                            publishBlog(content)
                        }
                    });
                });
            }
        }

    }


    const ValidateFeilds = () => {
        if (titleRef.current.value === "") {
            alert("Please enter a Blog title")
            return false
        }
        if (value === "" || value === "<p><br></p>") {
            alert("Please enter content for the blog")
            return false
        }
        if (CategorySelected === 0) {
            alert('Please select a category')
            return false
        }
        uploadImage()
    }


    // checking if a blog content contains any links or not?
    const LinksManagement = (flag) => {
        return flag;
    }


    const publishBlog = async (con) => {
        setLoading(true)
        const myTimestamp = Timestamp.fromDate(new Date());
        const NewId = uuidv4()
        await setDoc(doc(db, "root", NewId), {
            title: titleRef.current.value,
            content: con,
            attachedImage: imageUrls,
            createdAt: myTimestamp,
            category: CategorySelected === 1 ? catgs[1] : CategorySelected === 2 ? catgs[2] : CategorySelected === 3 ? catgs[3] : "Other",
            // viewCount: 0
        }).then(() => {
            console.log("success");
            CreateViewCollection(NewId)
            setLoading(false);
            navigate("/");
            navigate(0);
        })
    }

    // creating seperate view collection for the views of the blog
    const CreateViewCollection = async (Blogid) => {
        await setDoc(doc(db, "views", Blogid), {
            viewCount: 0
        })
    }

    const [CategorySelected, setCategorySelected] = useState(0)

    return (
        <>
            <div className='AddBlog_parentContainer'>
                <div className="AddBlog_Wrapper">
                    <div className='PostBlogButtonContainer'>
                        <h5>Post a blog</h5>
                        <button className='publish' onClick={ValidateFeilds}>Publish
                            <img src={send} alt="" />
                        </button>
                        {/* <button onClick={()=>{console.log(uIm);}}>check</button> */}
                    </div>


                    {/* title input  */}
                    <input className='Title_input' type="text" ref={titleRef} placeholder='Title of the blog' />

                    <div className="categorySelector">
                        <div className="Category" onClick={() => { setCategorySelected(1) }} style={{ backgroundColor: CategorySelected === 1 ? "#FFA401" : "transparent", color: CategorySelected === 1 ? "white" : 'Black' }} >{catgs[1]}</div>
                        <div className="Category" onClick={() => { setCategorySelected(2) }} style={{ backgroundColor: CategorySelected === 2 ? "#FFA401" : "transparent", color: CategorySelected === 2 ? "white" : 'Black' }} >{catgs[2]}</div>
                        <div className="Category" onClick={() => { setCategorySelected(3) }} style={{ backgroundColor: CategorySelected === 3 ? "#FFA401" : "transparent", color: CategorySelected === 3 ? "white" : 'Black' }} >{catgs[3]}</div>
                    </div>

                    {/* images upload */}
                    <label htmlFor="fileUpload" className="image-upload">
                        {
                            pI.length === 0 ? <div className="beforeUpload">
                                <img src={upload} alt="icon" />
                                Add Image (Optional)
                            </div> : <div id="imgPreview">
                                {pI.map((key) => (<img src={key.preURL} alt=""/>))}
                            </div>
                        }


                        <div id="Loader" style={{ display: "none" }}>
                            <ImageLoading />
                        </div>



                    </label>
                    <input type="file" id="fileUpload" multiple className="forHide" onChange={handleSubmit} />

                    <ReactQuill theme="snow" value={value} onChange={setValue} placeholder={"Type your blog content here..."} />
                </div>
            </div>
            {
                Loading ? <UniversalLoader /> : ""
            }
        </>
    )
}

function ImageLoading() {
    return <div className="ImageLoading"></div>
}

