import React, { useEffect, useState } from 'react';
import '../Styles/Details.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../Firebase';
import { UniversalLoader } from './SmallUIComponents';
import { getDownloadURL, uploadBytes } from 'firebase/storage';
import { ref as sRef } from "firebase/storage";
import { useNavigate } from 'react-router';
import { Loader } from './Loader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

export default function Details() {

    const [UserDetails, setUserDetails] = useState([])
    const [Loading, setLoading] = useState(false)
    const [ImgLoading, setImgLoading] = useState(false)
    const [FixUrl, setFixUrl] = useState('')
    const [FixUrlProfile, setFixUrlProfile] = useState('')
    const navigate = useNavigate()

    // selecting photo from gallry
    const handleSubmit = (e) => {
        const image = e.target.files[0];
        console.log("Image Selected", image)
        const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
        if (image && ALLOWED_TYPES.includes(image.type)) {
            // document.getElementById("Loader").style.display = "block";
            uploadImage(image, e.target.dataset.id)
        }
        else {
            alert("File format not supported")
        }
    }


    // // uploading selected image to the firebase storage 
    const uploadImage = async (image, where) => {
        setImgLoading(true)
        // uploading image
        const filename = where === "0" ? "profile" : "cover"
        const fileRef = sRef(storage, filename);
        await uploadBytes(fileRef, image).then(() => {
            getDownloadURL(fileRef).then((url) => {
                console.log("Image uploaded successfully");
                updateURL(url, where)
            })
        })
    }

    // updating profile and cover image url in the user firebase oject
    const updateURL = async (url, targ) => {
        const imgRef = doc(db, "user", "userDetails");
        if (targ === "0") {
            await updateDoc(imgRef, { ProfilePic: url }).then(() => {
                setFixUrlProfile(url)
                console.log("Profile URL updated successfully");
                setImgLoading(false)
            })
        }
        else if (targ === "1") {
            await updateDoc(imgRef, { CoverPic: url }).then(() => {
                console.log("Cover URL updated successfully");
                setFixUrl(url)
                setImgLoading(false)
            })
        }
        else {
            console.log("Something went wrong" + targ);
        }
    }


    // getting user details from firebase
    const getDetails = async () => {
        setLoading(true)
        const docRef = doc(db, "user", "userDetails");
        await getDoc(docRef).then((res) => {
            console.log(res.data());
            setUserDetails(res.data());
            setLoading(false)
        }).catch((err) => {
            console.log(err.message);
        })
    }

    // fetching user details from firebase
    useEffect(() => {
        getDetails()
    }, [])

    // code for editing of the user feilds

    const UserDetailsCopy = UserDetails

    // saving edits of user
    const saveUserDetails = async () => {
        setLoading(true)
        const userRef = doc(db, "user", "userDetails");
        await updateDoc(userRef, {
            Name: UserDetailsCopy.Name,
            Email: UserDetailsCopy.Email,
            Phone: UserDetailsCopy.Phone,
            InstagramURL: UserDetailsCopy.InstagramURL,
            FacebookURL: UserDetailsCopy.FacebookURL,
            TwitterURL: UserDetailsCopy.TwitterURL,
            YoutubeURL: UserDetailsCopy.YoutubeURL,
            KooURL: UserDetailsCopy.KooURL,
            About: UserDetailsCopy.About
        }).then(() => {
            console.log("Details Saved");
            navigate("/admin")
            setLoading(false)
        })
    }

    return (
        <div>
            {
                Loading ? <UniversalLoader /> : <div className='DetailsWrapper'>
                    <div className="details">
                        <div className="head">
                            <h5>Click to edit details</h5>
                            <button className="save" onClick={saveUserDetails}>Save</button>
                            {/* <button className="save" onClick={() => { console.log(UserDetailsCopy); }}>check</button> */}
                        </div>

                        {/* uploading new profile and cover image */}
                        {/* <input type="file" id="fileUploadCover" data-id={1} className="forHide" onChange={handleSubmit} />
                        <label className="Photos" htmlFor='fileUploadCover' style={{ background: FixUrl === '' ? `url("${UserDetails?.CoverPic}") no-repeat center/cover` : `url("${FixUrl}") no-repeat center/cover` }}>
                           
                        </label> */}
                        <label className="profileOne" htmlFor="fileUpload" style={{ background: FixUrlProfile === '' ? `url("${UserDetails?.ProfilePic}") no-repeat center/cover` : `url("${FixUrlProfile}") no-repeat center/cover` }}>
                            <input type="file" id="fileUpload" data-id={0} className="forHide" onChange={handleSubmit} />
                        </label>
                        {ImgLoading ? <Loader /> : ''}


                        <div className="editableUserDetails">
                            {/* <p>{nameRef.current.value}</p> */}
                            <Input label={"Name"} dValue={UserDetails?.Name} data={UserDetailsCopy} qName={"Name"} />
                            <Input label={"Designation"} dValue={UserDetails?.Email} data={UserDetailsCopy} qName={"Email"} />
                            <Input label={"Phone"} dValue={UserDetails?.Phone} data={UserDetailsCopy} qName={"Phone"} />
                            <Input label={"About You"} dValue={UserDetails?.About} data={UserDetailsCopy} qName={"About"} />
                            <Input label={"Instagram Profile URL"} dValue={UserDetails?.FacebookURL} data={UserDetailsCopy} qName={"InstagramURL"} />
                            <Input label={"Twitter Profile URL"} dValue={UserDetails?.TwitterURL} data={UserDetailsCopy} qName={"TwitterURL"} />
                            <Input label={"Facebook Profile URL"} dValue={UserDetails?.FacebookURL} data={UserDetailsCopy} qName={"FacebookURL"} />
                            <Input label={"YouTube URL"} dValue={UserDetails?.YoutubeURL} data={UserDetailsCopy} qName={"YoutubeURL"} />
                            <Input label={"Koo Profile URL"} dValue={UserDetails?.KooURL} data={UserDetailsCopy} qName={"KooURL"} />
                            {/* <Input label={"LinkedIN Profile URL"} value={UserDetails.URL} /> */}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

function Input(props) {
    const handleChangeInput = (e) => {
        if (props.qName === "Name") { props.data.Name = e.target.value }
        else if (props.qName === "Email") { props.data.Email = e.target.value }
        else if (props.qName === "Phone") { props.data.Phone = e.target.value }
        else if (props.qName === "FacebookURL") { props.data.FacebookURL = e.target.value }
        else if (props.qName === "TwitterURL") { props.data.TwitterURL = e.target.value }
        else if (props.qName === "InstagramURL") { props.data.InstagramURL = e.target.value }
        else if (props.qName === "YoutubeURL") { props.data.YoutubeURL = e.target.value }
        else if (props.qName === "KooURL") { props.data.KooURL = e.target.value }
        // else if (props.qName === "About") { props.data.About = Aboutvalue }
    }

    const [Aboutvalue, setAboutvalue] = useState(props.dValue)

    useEffect(() => {
        //   console.log(props.data);
        props.data.About = Aboutvalue
    }, [Aboutvalue])


    return <div className="editable_Input">
        <label className="label">{props.label}</label>
        {
            props.qName === 'About' ?
                <ReactQuill
                    theme="bubble"
                    value={Aboutvalue}
                    onChange={setAboutvalue}
                    defaultValue={props.dValue}
                />
                :
                <input
                    className="input"
                    defaultValue={props.dValue}
                    onChange={handleChangeInput}
                />
        }
    </div>
}