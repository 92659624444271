import React, { useState } from 'react'
import '../Styles/Admin.css'
import logo from '../Frame.svg'
import LandingPage from '../SmallComponents/LandingPage'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { deleteBlog, setBlogs, setLastblog } from '../Redux/Slices/BlogsSlice';
import { db, storage } from '../Firebase';
import { collection, getDocs, query, orderBy, startAfter, limit, deleteDoc, doc } from 'firebase/firestore';
import { Loader } from '../SmallComponents/Loader';
import { ConfirmationPopup, UniversalLoader } from '../SmallComponents/SmallUIComponents';
import { useNavigate } from 'react-router';
import { deleteObject, ref } from 'firebase/storage';
import Drawer from './Drawer';

export default function Admin() {

    const Blogs = useSelector(state => state.Blogs.FetchedBlogs)
    const LastBlog = useSelector(state => state.Blogs.LastBlog)
    const Loading = useSelector(state => state.Blogs.Loading)
    const dispatch = useDispatch();

    const [isReachedBottom, setisReachedBottom] = useState(false)
    const [LoadingMoreBlogs, setLoadingMoreBlogs] = useState(false)


    useEffect(() => {
        function handleScroll() {
            if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
                setisReachedBottom(true)
            }
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const FetchNextBlogs = async () => {
        setLoadingMoreBlogs(true)
        let newBlogs = [];
        const newBlogsQuery = query(collection(db, 'root'), orderBy("createdAt", "desc"), limit(6), startAfter(LastBlog))
        if (LastBlog === undefined || LastBlog === null || LastBlog.length === 0 || Blogs.length === 0) {
            setLoadingMoreBlogs(false)
            console.log(LastBlog);
        }
        const querySnapshot = await getDocs(newBlogsQuery);
        querySnapshot.forEach((doc) => {
            newBlogs.push({
                BlogTitle: doc.data().title,
                BlogImage: doc.data().attachedImage,
                BlogContent: doc.data().content,
                createdAt: doc.data().createdAt,
                BlogID: doc.id
            })
        })
        dispatch(setBlogs(newBlogs))
        const last = querySnapshot.docs[querySnapshot.docs.length - 1];
        dispatch(setLastblog(last));
        setisReachedBottom(false)
        setTimeout(() => {
            setLoadingMoreBlogs(false)
        }, 1000);
    }

    // fetch more blogs if user reacht o bottom of the page
    useEffect(() => {
        if (isReachedBottom) {
            if (Blogs.length !== 0) {
                FetchNextBlogs()
            }
        }
    }, [isReachedBottom])

    const [IgnoreFlag, setIgnoreFlag] = useState(11)

    return (
        <div className='admin_wrapper'>
            <LandingPage isProfile={true} isBlog={true} isAdmin={true} IgnoreFlag={IgnoreFlag} setIgnoreFlag={setIgnoreFlag} />
            {/* listing all blogs */}
            <div className="blogList" id='blogsContainer' style={{ zIndex: IgnoreFlag }}>
                <p>All Blogs</p>
                {
                    Blogs.map((key) => {
                        return <Blog
                            title={key.BlogTitle}
                            id={key.BlogID}
                            image={key.BlogImage}
                        />
                    })
                }
                {/* loading when fetching more blogs | Getting laoding from usestate, manually */}{
                    LoadingMoreBlogs ? <div className='MoreBlogsLoading'>
                        <Loader />
                    </div> : ''
                }

                {/* loading when fetching initial blogs | Getting loading from redux store */}{
                    Loading !== "idle" ? <div className='MoreBlogsLoading'>
                        <Loader />
                    </div> : ''
                }
            </div>
            <Drawer />
        </div>
    )
}

// https://firebasestorage.googleapis.com/v0/b/blog-d9cac.appspot.com/o/3105ee81-897c-4480-b95c-c961eb4318d7?alt=media&token=7d2f9be6-0db3-4c86-85d6-2d50fa590a0c

function Blog(props) {
    const [Cnf_Popup, setCnf_Popup] = useState(false)
    const [Loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const GetImgName = () => {
        for (let l = 0; l < props.image.length; l++) {
            let url = props.image[l].url
            console.log(url);
            if (url !== "") {
                let Clipback = url.substring(url.indexOf("/o/"), url.indexOf("?"))
                let ClipFront = Clipback.replace("/o/", "")
                let desertRef = ref(storage, ClipFront);
                deleteObject(desertRef).then(() => {
                    console.log("file deleted successfully");
                    // setDeletingImage(false)
                    // dispatch(deleteImages(props.ImgID))
                    // navigate(0)
                }).catch((error) => {
                    console.log("error: " + error.message);
                    // setDeletingImage(false)
                });
            }
        }

        // const url = props?.image
        // if (url !== "") {
        //     let Clipback = url.substring(url.indexOf("/o/"), url.indexOf("?"))
        //     let ClipFront = Clipback.replace("/o/", "")
        //     let desertRef = ref(storage, ClipFront);
        //     deleteObject(desertRef).then(() => {
        //         console.log("file deleted successfully");
        //     }).catch((error) => {
        //         console.log("error: " + error.message);
        //     });
        // }
    }

    // // delete a blog
    const DeleteBlog = () => {
        setLoading(true)
        deleteDoc(doc(db, "root", props.id))
            .then(() => {
                DeleteViewCollection()
                GetImgName()
                setCnf_Popup(false)
                dispatch(deleteBlog(props.id))
            })
    }
    const DeleteViewCollection = async () => {
        await deleteDoc(doc(db, 'views', props.id)).then(() => {
            setLoading(false)
        })
    }
    const closePopup = () => { setCnf_Popup(false) }

    return <div className="blog">
        <div className="blogInner">
            <div className="blogtitle" onClick={() => { navigate(`/blog/${props.id}`) }}>
                {props.title}
            </div>
        </div>
        <div className='deleteButton' onClick={() => { setCnf_Popup(true) }}>
            <img src={logo} alt="" />
        </div>
        {
            Cnf_Popup ? <ConfirmationPopup
                statement={"Are you sure you want to delete this Blog?"}
                pTitle={"Delete"}
                sTitle={"Cancel"}
                pAction={DeleteBlog}
                sAction={closePopup}
            /> : ''
        }
        {
            Loading ? <UniversalLoader /> : ''
        }
    </div>
}
