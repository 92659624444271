import React from 'react'
import '../Styles/Landing.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { db } from '../Firebase.js';
import { Navigation, Socials } from './SmallUIComponents';
import { doc, getDoc } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../Redux/Slices/userSlice';
import hmbg from '../assets/hmBG.svg'
import { LoaderTwo } from './Loader';

export default function LandingPage(props) {

    const [opacity, setOpacity] = useState(1);
    const navigate = useNavigate()
    const [Loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const UserDetails = useSelector(state => state.UserDetails.UserDetails)

    useEffect(() => {

        const getDetails = async () => {
            setLoading(true)
            const docRef = doc(db, "user", "userDetails");
            await getDoc(docRef).then((res) => {
                // console.log(res.data());
                // setUserDetails(res.data());
                dispatch(setUser(res.data()))
                setLoading(false)
            }).catch((err) => {
                console.log(err.message);
            })
        }

        if (UserDetails.length === 0) {
            getDetails()
        }
    }, [])

    useEffect(() => {
        function handleScroll() {
            const newOpacity = 1 - window.scrollY / window.innerHeight;
            setOpacity(newOpacity)
        }

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (
        <>
            <div className='landingPageContainer' style={{ opacity: opacity }}>
                {
                    props.isAdmin ? <Navigation IgnoreFlag={props.IgnoreFlag} setIgnoreFlag={props.setIgnoreFlag} />
                        : <div className="decorationBG">
                            <img src={hmbg} alt="" className='hmbg' />
                            <div className="ProfilePhoto">
                                {!Loading ? <img src={`${UserDetails?.ProfilePic}`} /> : <LoaderTwo />}
                            </div>
                            <div className="userDetails">
                                <div className="Gap"></div>
                                <div className="Gap"></div>
                                <h3>{!Loading ? UserDetails.Name : "Loading..."}</h3>
                                <div className="Gap"></div>
                                <p>{!Loading ? UserDetails.Email : "Loading"}</p>
                            </div>
                        </div>
                }
                {/* <div className="overlay"></div> */}
            </div>

        </>
    )
}

