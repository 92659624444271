import React, { useState } from 'react'
import QuestionTile from '../SmallComponents/QuestionTile'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setBlogs, setLastblog } from '../Redux/Slices/BlogsSlice';
import LandingPage from '../SmallComponents/LandingPage';
import { db } from '../Firebase';
import { collection, getDocs, query, orderBy, startAfter, limit} from 'firebase/firestore';
import { Loader } from '../SmallComponents/Loader';
import Drawer from './Drawer';
import dhore from '../assets/Dhore.svg'
import camel from '../assets/camel.svg'
import { Dropdown, catgs } from '../SmallComponents/SmallUIComponents';


export default function Homepage() {

    const dispatch = useDispatch();
    const Blogs = useSelector(state => state.Blogs)
    const LastBlog = useSelector(state => state.Blogs.LastBlog)
    const Loading = useSelector(state => state.Blogs.Loading)
    const [LoadingMoreBlogs, setLoadingMoreBlogs] = useState(false)
    const [isReachedBottom, setisReachedBottom] = useState(false)

    const FetchNextBlogs = async () => {
        setLoadingMoreBlogs(true)
        let newBlogs = [];
        const newBlogsQuery = query(collection(db, 'root'), orderBy("createdAt", "desc"), limit(3), startAfter(LastBlog))
        if (LastBlog === undefined || LastBlog === null || LastBlog.length === 0 || Blogs.FetchedBlogs.length === 0) {
            setLoadingMoreBlogs(false)
            console.log(LastBlog);
        }
        const querySnapshot = await getDocs(newBlogsQuery);
        querySnapshot.forEach((doc) => {
            newBlogs.push({
                BlogTitle: doc.data().title,
                BlogImage: doc.data().attachedImage,
                BlogContent: doc.data().content,
                createdAt: doc.data().createdAt,
                BlogID: doc.id,
                BlogCategory: doc.data().category,
                // BlogViewCount: doc.data().viewCount
            })
        })
        dispatch(setBlogs(newBlogs))
        const last = querySnapshot.docs[querySnapshot.docs.length - 1];
        dispatch(setLastblog(last));
        setisReachedBottom(false)
        setTimeout(() => {
            setLoadingMoreBlogs(false)
        }, 1000);
    }

    useEffect(() => {
        if (isReachedBottom) {
            if (Blogs.FetchedBlogs.length !== 0) {
                FetchNextBlogs()
            }
        }
    }, [isReachedBottom])
    useEffect(() => {
        function handleScroll() {
            if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
                setisReachedBottom(true)
                console.log("bottom reached");
            }
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // catgs is the universal category storing variable
    const [SelectedCategory, setSelectedCategory] = useState(catgs[0])
    const filteredBlogs = Blogs.FetchedBlogs.filter((key) => {
        if (SelectedCategory === catgs[0]) {
            return key
        }
        else {
            return key.BlogCategory === SelectedCategory;
        }
    });


    // scroll to section
    function scrollToSection(key) {
        if (key !== null && key !== undefined) {
            window.scrollTo({
                top: document.getElementById(key).offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        }
    };

    return (
        <>
            {/* <Navbar /> */}
            <LandingPage isBlog={false} />
            <div className="hompageContainer" id='homepage'>
                {Blogs.FetchedBlogs.length !== 0 ? <div className="up" onClick={() => scrollToSection('homepage')}><i class="fas fa-long-arrow-alt-up"></i></div> : ''}
                <img src={dhore} alt="" className='dhore' />
                <img src={camel} alt="" className='camel' />

                <Dropdown setItem={setSelectedCategory} currentItem={SelectedCategory} />

                {/* questions container */}
                <div className="question-container" id='scrollContainer'>

                    {
                        Loading !== "loading" ? Blogs.FetchedBlogs.length === 0 ? <h6 style={{ marginLeft: '20px', marginTop: "10px", color: 'grey' }}>No Blogs Found</h6> : '' : ""
                    }

                    {/* all questions will fetch here */}
                    {
                        Loading === 'loading' ? <Loader />
                            :
                            filteredBlogs.map((key) => {
                                return <QuestionTile
                                    key={key.BlogID}
                                    questionTitle={key.BlogTitle}
                                    image={key.BlogImage}
                                    content={key.BlogContent}
                                    dateTime={key.createdAt}
                                    id={key.BlogID}
                                    category={key.BlogCategory}
                                    viewCount={key.BlogViewCount}
                                />
                            })
                    }

                    {
                        LoadingMoreBlogs ? <div className='MoreBlogsLoading'>
                            <Loader />
                        </div> : ''
                    }
                    {/* <div className="LoadMore" onClick={FetchNextBlogs}>Load more blogs...</div> */}

                </div>
                {/* related questions */}
                <div className="related-questions">

                </div>
                <Drawer />
            </div>
        </>
    )
}
