import { Markup } from 'interweave';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import buttondhore from '../assets/buttonDhore.svg'
import buttoncamel from '../assets/buttonCamel.svg'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Firebase';

export default function QuestionTile(props) {
    const date = props.dateTime.toDate().toDateString()
    const time = props.dateTime.toDate().toLocaleTimeString()
    const navigate = useNavigate();
    SwiperCore.use([Autoplay, Pagination])

    function handleShareClick() {
        if (navigator.share) {
            navigator.share({
                title: "Praveen Kumar Makwana Blogs",
                text: "Check out this blog by Praveen Kumar Makwana",
                url: `${window.location.href}blog/${props.id}`,
            });
        } else {
            // Fallback for browsers that do not support Web Share API
            alert("Sharing is not supported on this device/browser.");
        }
    }

    // enlarging image when user clicks on a image
    const [data, setData] = useState('')
    const ViewImage = (img) => {
        setData(img)
        // console.log("here is the sending data : "+img);
    }
    useEffect(() => {
        if (data !== '') {
            navigate('/img', {
                state: {
                    image: data
                }
            })
        }
    }, [data])


    const [BlogView, setBlogView] = useState('Loading ')
    // function for fetching blog views
    const FetchViews = async () => {
        const view = await getDoc(doc(db, "views", props.id))
        setBlogView(view.data().viewCount)
    }
    useEffect(() => {
        FetchViews()
    }, [])



    return (
        <div className="question-tile">

            {/* title of the blog */}
            <div className="question-title"
            //  onClick={() => { navigate(`/blog/${props.id}`) }} target='_blank'
            >
                {props.questionTitle}
            </div><div className="Gap"></div>

            {/* time and date of the blog */}
            <div className="timeDate">{date} | {time}</div>
            <div className="Gap"></div>
            <div className="Gap"></div>
            {/* Category of the blog */}
            <div className="flex">
                <div className="Category">{props.category}</div>
                <div className="ViewCount">
                    <i class="far fa-eye fa-sm"></i>
                    {BlogView === "Loading " ? "Loading" : `${Math.floor(BlogView + ((BlogView*35)/100))} views`}

                </div>
            </div>
            <div className="Gap"></div>
            <div className="Gap"></div>
            <div className="Gap"></div>


            {/* images of the blog if any */}
            {
                props.image.length !== 0 ?
                    <div>
                        <Swiper
                            modules={[Autoplay]}
                            className="ImagesSwiper"
                            spaceBetween={6}
                            slidesPerView={1}
                            autoplay={{ delay: 3000, disableOnInteraction: false }}
                            pagination={true}
                        // loop={true}
                        >
                            {props.image.map((key) => (

                                <SwiperSlide className='ImageCard' onClick={() => ViewImage(key.url)} style={{ background: `url("${key.url}") no-repeat center/cover` }}>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="Gap"></div>
                        <div className="Gap"></div>
                        <div className="Gap"></div>
                    </div>

                    : ''
            }





            {/* sliced content of the blog*/}{
                props.content !== "" ? <div id='blogContent' style={{ fontSize: '14px' }}>
                    {/* {`${props.content.substring(0, 500)}...`} */}
                    <Markup content={`${props.content.substring(0, 200)}`} />
                </div> : ''
            }
            <div className="Gap"></div>
            <div className="Gap"></div>


            {/* Openblog and share button */}
            <div className="blogActions">
                <div onClick={() => {
                    window.open(`/blog/${props.id}`, "_blank", "noreferrer")
                }} target='_blank' className="openBlogButton">

                    {/* button decoration */}
                    {/* <img src={buttondhore} alt="" className='buttonDhore' /> */}
                    {/* <img src={buttoncamel} alt="" className='buttoncamel' /> */}


                    <div>Read full blog</div>
                </div>
                <div className="shareButton" onClick={handleShareClick}>
                    <i className="fas fa-share fa-sm"></i>
                </div>
            </div>
            <div className="Gap"></div>
        </div>
    )
}

// style={{ background: `url("${}") no-repeat center/cover` }}
