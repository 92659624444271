import { createSlice, current } from "@reduxjs/toolkit";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { db } from "../../Firebase";

const GalleryStatus = Object.freeze({
    IDLE: 'idle',
    LOADING: 'loading',
    ERROR: 'error'
})

const Gallery = createSlice({
    name: 'gallery',
    initialState: {
        Loading: GalleryStatus.LOADING,
        FetchedImages: [],
        LastImage: []
    },
    reducers: {
        toggleLoading(state, action) {
            state.Loading = action.payload
            return state
        },
        setImages(state, action) {
            state.FetchedImages = state.FetchedImages.concat(action.payload)
        },
        setLastImage(state, action) {
            state.LastImage = action.payload
            return state
        },
        deleteImages(state, action) {
            const target = current(state).FetchedImages
            const res = target.filter((key) => key.ImgID !== action.payload)
            state.FetchedImages = res
            return state
        }
    }
})

export const {setImages, setLastImage, toggleLoading, deleteImages} = Gallery.actions;
export default Gallery.reducer;

export function GetImages(){
    return async function fetchImageWithReduxThunk(dispatch){
        dispatch(toggleLoading(GalleryStatus.LOADING))
        let AllImages = [];
        const modQuerry = query(collection(db, "gallery"), orderBy("createdAt", 'desc'), limit(6));
        const querySnap = await getDocs(modQuerry);
        querySnap.forEach((doc)=>{
            AllImages.push({
                ImageAbout : doc.data().ImgAbout,
                ImageUrl : doc.data().ImgURL,
                createdAt : doc.data().createdAt,
                ImgID: doc.id
            })
        })

        dispatch(toggleLoading(GalleryStatus.IDLE))
        dispatch(setImages(AllImages))

        const last = querySnap.docs[querySnap.docs.length - 1];
        dispatch(setLastImage(last));
    }
}