import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { UniversalLoader } from "./SmallUIComponents";
import { auth } from "../Firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useState } from "react";

const ProtectedRoute = () => {

    const accessTokenRedux = useSelector(state => state.Auth.Auth)
    const UserLoad = useSelector(state => state.Auth.Loading)
    // const [AuthLoadDone, setAuthLoadDone] = useState(false)

    // console.log("Access token in Redux is : " + accessTokenRedux);


    // for remembering the logged user, if user open webapp in new browser window.

    // const [isAuthFirebase, setisAuthFirebase] = useState(false)
    // useEffect(() => {
    //     const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
    //         setAuthLoadDone(false)
    //         // console.log("Access token in protected route is : " + currentuser?.accessToken);
    //         // console.log(currentuser);

    //         if (currentuser !== null && currentuser !== undefined) {
    //             if (currentuser.accessToken.length > 0 && currentuser.uid === 'hQy6OZyt8KYq9fOXcZ1xnoMGoly1') {
    //                 setisAuthFirebase(true)
    //             }
    //         }

    //         setTimeout(() => {
    //             setAuthLoadDone(true)
    //         }, 4000);
    //     });

    //     return () => {
    //         unsubscribe();
    //     };
    // }, []);


    // const CheckAuthenticity = () => {
    //     onAuthStateChanged(auth, (currentUser) => {
    //         return currentUser
    //     })
    // }

    // useEffect(() => {
    //     setAuthLoadDone(false)
    //     const a = CheckAuthenticity()
    //     if (a !== null && a !== undefined) {
    //         setAuthLoadDone(true)
    //     }
    // }, [])



    if (UserLoad !== "loading") {
        if (accessTokenRedux !== null && accessTokenRedux !== undefined && accessTokenRedux !== "" && accessTokenRedux.length > 0) { return <Outlet />; }
        else { return <Navigate to="/profile" /> }
    }
    else { return <UniversalLoader /> }
};
export default ProtectedRoute;