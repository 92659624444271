import React, { useRef } from 'react'
import '../Styles/Drawer.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
// import { doc, getDoc } from 'firebase/firestore'
// import { db } from '../Firebase'
import { useSelector } from 'react-redux'
import drawerimg from '../assets/Drawer.svg'

export default function Drawer(props) {
    const [IsMenuActive, setIsMenuActive] = useState(false)
    const [DynamicStyle, setDynamicStyle] = useState("translateX(100%)")
    // const [UserDetails, setUserDetails] = useState(null)
    const navigate = useNavigate()
    const UserDetails = useSelector(state => state.UserDetails.UserDetails)

    useEffect(() => {
        if (IsMenuActive) {
            setDynamicStyle("translateX(0%)")
        }
        else {
            setDynamicStyle("translateX(100%)")
        }
    }, [IsMenuActive])


    // for closing onclick outside of the drawer
    const drawerRef = useRef(null)
    const buttonRef = useRef(null)
    useEffect(() => {
        const handleOutsideClick = (event) => { if (IsMenuActive && drawerRef.current && !drawerRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) { setIsMenuActive(false) } }
        document.addEventListener('click', handleOutsideClick, true);
        return () => { document.removeEventListener('click', handleOutsideClick, true) }
    }, [IsMenuActive]);

    return (
        <div>
            {
                props.isHide ? '' : <div className="buttonContainer" onClick={() => { setIsMenuActive(!IsMenuActive) }} ref={buttonRef}>
                    <div className="button" tabindex="0">
                        {
                            IsMenuActive ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>
                        }
                    </div>
                </div>
            }

            <div id="nav-container" style={{ transform: DynamicStyle }} ref={drawerRef}>
                <img src={drawerimg} alt="" />
                <div id="nav-content">

                    <div className='li' onClick={() => {
                        navigate("/admin")
                        setIsMenuActive(false)
                    }}>Admin</div>

                    <div className="Gap"></div>
                    <div className="line"></div>
                    <div className="Gap"></div>

                    <div className='li' onClick={() => {
                        navigate("/")
                        setIsMenuActive(false)
                    }}>Blogs</div>

                    <div className='li' onClick={() => {
                        navigate("/about")
                        setIsMenuActive(false)
                    }}>About</div>

                    <div onClick={() => {
                        navigate('/gallery')
                        setIsMenuActive(false)
                    }} className='li'>Gallery</div>

                    <div className="Gap"></div>
                    <div className="line"></div>
                    <div className="Gap"></div>
                    
                    <div className='li' onClick={() => { if (UserDetails.FacebookURL !== '') { window.open(UserDetails?.FacebookURL, "_blank", "noreferrer") } else { alert('No Link Available') } }} >Facebook</div>
                    <div className='li' onClick={() => { if (UserDetails.TwitterURL !== '') { window.open(UserDetails?.TwitterURL, "_blank", "noreferrer") } else { alert('No Link Available') } }} >Twitter</div>
                    <div className='li' onClick={() => { if (UserDetails.InstagramURL !== '') { window.open(UserDetails?.InstagramURL, "_blank", "noreferrer") } else { alert('No Link Available') } }} >Instagram</div>
                    <div className='li' onClick={() => { if (UserDetails.KooURL !== '') { window.open(UserDetails?.KooURL, "_blank", "noreferrer") } else { alert('No Link Available') } }} >Koo</div>
                    <div className='li' onClick={() => { if (UserDetails.YoutubeURL !== '') { window.open(UserDetails?.YoutubeURL, "_blank", "noreferrer") } else { alert('No Link Available') } }} >Youtube</div>
                </div>
            </div>
            <div className="bg" style={{ display: IsMenuActive ? "block" : "none" }}></div>
        </div>
    )
}
